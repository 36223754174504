import LandingPage from './views/LandingPage';
// import InventoryPage from './views/InventoryPage';
// import ContactPage from './views/ContactPage';
// import AboutUsPage from './views/AboutUsPage';
// import CMSPage from './views/CMSPage';
// import CarDetailPage from './views/CarDetailPage';
// import LoginPage from './views/LoginPage';
import React, {useEffect, useState} from 'react';
// eslint-disable-next-line no-unused-vars
import {BrowserRouter, Routes, Route, Navigate, useRoutes} from 'react-router-dom';
import {getAuth, onAuthStateChanged} from 'firebase/auth';

// Create a user context to manage authentication
const AuthContext = React.createContext();
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
        console.log(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Unsubscribe from the onAuthStateChanged observer when this component is unmounted
    return () => unsubscribe();
  }, []);

  // const ProtectedRoute = ({user, redirectPath = '/login', children}) => {
  //   if (!user) {
  //     return <Navigate to={redirectPath} replace />;
  //   }

  //   return children;
  // };


  const RoutesComponent = () => {
    return useRoutes([
      {path: '/', element: <LandingPage />},
      // {path: '/aanbod', element: <InventoryPage />},
      // {path: '/contact', element: <ContactPage />},
      // {path: '/over-ons', element: <AboutUsPage />},
      // {path: '/auto/:id', element: <CarDetailPage />},
      // {path: '/login', element: user ? <Navigate to="/" replace /> : <LoginPage />},
      // {
      //   path: '/cms',
      //   element:
      //     <ProtectedRoute user={user}>
      //       <CMSPage />
      //     </ProtectedRoute>,
      // },
      // {
      //   path: '/cms/:id',
      //   element:
      //     <ProtectedRoute user={user}>
      //       <CMSPage />
      //     </ProtectedRoute>,
      // },
    ]);
  };

  return (
    <AuthContext.Provider value={user}>
      <BrowserRouter>
        <RoutesComponent />
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
