/* eslint-disable react/no-unescaped-entities */
import React, {useEffect} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Navbar2 from '../components/Navbar2';
// import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import WelcomeMessage from '../components/WelcomeMessage';
import '../assets/css/LandingPage.css';
// import FeaturedCars from '../components/FeaturedCars';
// import Container from '../components/Container';
import incoming from './incoming.jpg';

const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
  // <div className="bg-gradient-to-b from-gray-900 to-gray-800 text-white min-h-screen">
  //   <Navbar2 />
  //   <div className="p-8">
  //     <WelcomeMessage />
  //   </div>
  //   <div className="flex-grow p-8">
  //     <Container>
  //       <FeaturedCars />
  //     </Container>
  //   </div>
  //   <Footer />
  // </div>

    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center relative px-4">
      <div className="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-30
        z-0" style={{backgroundImage: `url(${incoming})`}}>
      </div>
      <h1 className="text-5xl md:text-7xl text-white font-bold mb-8 z-10">Onder constructie</h1>
      <p className="text-white text-xl md:text-2xl">
        Hopelijk kunnen we binnenkort onze website lanceren!
      </p>
    </div>
  );
};

export default LandingPage;
