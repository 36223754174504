// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// const {initializeApp} = require('firebase/app');

import {initializeApp} from 'firebase/app';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCXLGhhZV_B7HxPAY6pD2uQQepWO9HQESg',
  authDomain: 'ronnie-auto.firebaseapp.com',
  projectId: 'ronnie-auto',
  storageBucket: 'ronnie-auto.appspot.com',
  messagingSenderId: '15409425142',
  appId: '1:15409425142:web:d9bef5345b4fa30ad95ad7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
